<template>
  <div class="view-indicators">
    <div class="view-indicators__select">
      <div class="view-indicators__select-item">
        <p>{{ $t('year') }}</p>
        <ui-select v-model="year" :options="getYears" @input="getRegions"/>
      </div>
      <div class="view-indicators__select-item">
        <p>{{ $t('region') }}</p>
        <ui-select v-model="region" :options="dataRegions" @input="getTypes"/>
      </div>
      <div class="view-indicators__select-item">
        <p>{{ $t('type-2') }}</p>
        <ui-select v-model="type" :options="typeOptions" @input="getSubtypes"/>
      </div>
      <div class="view-indicators__select-item">
        <p>{{ $t('subtype') }}</p>
        <ui-select v-model="subtype" :options="subtypeOptions" @input="getRegionGroups"/>
      </div>
    </div>
    <UiLoader v-model="loader"/>
    <UiNotification v-model="showNotification" :message="message" />
    <template v-if="!loader && subtype.name && tableData?.length">
      <div class="view-indicators__table" v-for="(item, key) in tableData" :key="key" v-show="item.data?.length">
        <div class="view-indicators__table-actions" :style="{maxWidth: '850px'}">
          <div class="actions-item" :style="{maxWidth: '180px'}">
            <span>{{ $t('type-2') }}:</span>
            <p>{{ item.typeName }}</p>
          </div>
          <div class="actions-item" :style="{maxWidth: subtypeWidth(item.subtypeName?.length)}">
            <span>{{ $t('subtype') }}:</span>
            <p>{{ item.subtypeName }}</p>
          </div>
          <div class="actions-item">
            <span>{{ $t('group') }}:</span>
            <p>{{ item.groupName }}</p>
          </div>
        </div>
        <RegionIndicatorsTable
          :descriptionList="[
            {title: $t('indicator'), width: 380},
            {title: $t('unit-of-measurement'), width: 131, paddingLeft: 0, textAlign: 'center'},
            {title: $t('value'), width: 110, paddingLeft: 0, textAlign: 'center'},
            {title: $t('points-2'), width: 90, paddingLeft: 0, textAlign: 'center'},
            {title: $t('summarized-weight'), width: 130, paddingLeft: 0, textAlign: 'center'},
            {title: $t('weighted-value'), width: 130, paddingLeft: 0, textAlign: 'center'},
          ]"
          :data="item.data"
          isView
        />
      </div>
    </template>
    <div class="empty" v-if="!loader && subtype.name && isEmpty === 0">
      {{ $t('empty') }}
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiNotification from "@/components/ui/UiNotification.vue";
import RegionIndicatorsTable from "@/components/template/moderatorRegion/regionIndicators/RegionIndciatorsTable.vue";

export default {
  name: "ViewIndicators",
  components: {RegionIndicatorsTable, UiNotification, UiLoader, UiSelect},

  data () {
    return {
      year: {},
      region: {},
      type: {},
      subtype: {},
      loader: false,
      showNotification: false,
      message: '',
      tableData: [],
      typeOptions: [],
      subtypeOptions: [],
      allSubtypes: [],
      isEmpty: 0
    }
  },

  computed: {
    ...mapGetters([
      'dataRegions',
      'getIndicators',
      'getRegionTypes',
      'getRegionSubtypes',
      'getGroups',
      'getYears',
      'getUser'
    ]),
  },

  methods: {
    ...mapActions([
      'regions',
      'indicators',
      'addIndicatorsRegionValue',
      'editIndicators',
      'regionTypes',
      'regionSubtypes',
      'groups',
      'years'
    ]),
    ...mapMutations([
      'setDataRegions',
      'setRegionSubtypes',
      'setIndicators',
      'setYears'
    ]),

    subtypeWidth (length) {
      return length < 15 ? 'fit-content' :
             length < 23 ? '200px' :
             length < 30 ? '250px' :
             length < 40 ? '300px' :
             length < 50 ? '350px' :
             length < 60 ? '400px' : '250px'
    },

    getRegions () {
      this.region = {}
      this.type = {};
      this.subtype = {};
      this.typeOptions = []
      this.subtypeOptions = []
      this.allSubtypes = []
      this.setDataRegions([])
      this.regions({skip: 0, take: 200, sort: 'name', order: 'ASC'}).then((res) => {
        this.setDataRegions(res.data)
      })
    },

    getTypes () {
      this.type = {};
      this.subtype = {};
      this.typeOptions = []
      this.subtypeOptions = []
      this.allSubtypes = []
      this.regionTypes(this.year.value).then((res) => {
        this.typeOptions = res
        if(res?.length) {
          this.typeOptions.push({name: 'Все', value: 'all', ord: 0, id: 0})
          this.typeOptions.sort((a, b) => a.ord-b.ord)
        }
      })
      this.setRegionSubtypes([])
      this.setIndicators([])
      this.tableData = []
    },

    getSubtypes () {
      this.subtype = {};
      this.tableData = []
      this.subtypeOptions = []
      this.allSubtypes = []
      this.setIndicators([])
      this.setRegionSubtypes([])
      this.subtypeOptions.push({name: 'Все', value: 'all', id: 0})
      if (this.type.id) {
        this.regionSubtypes({typeId: this.type.id}).then((res) => {
          if(res?.length > 1) {
            res.forEach(subtype => {
              this.subtypeOptions.push(subtype)
              this.allSubtypes.push(subtype)
            })
          } else {
            this.subtypeOptions = res
            this.allSubtypes = res
          }
        })
      } else {
        this.typeOptions.forEach(type => {
          if(type.id) {
            this.regionSubtypes({typeId: type.id}).then((res) => {
              res.forEach(subtype => {
                this.allSubtypes.push(subtype)
              })
            })
          }
        })
      }
      this.subtypeOptions.sort((a, b) => a.id-b.id)
    },

    getRegionGroups () {
      this.loader = true;
      this.setIndicators([])
      this.tableData = [];
      this.isEmpty = 0;
      if (this.subtype.id) {
        this.groups({subtypeId: this.subtype.id}).then(() => {
          this.getGroups.forEach(group => {
            this.indicators({year: this.year.value, regionId: this.region.id, type: 'Calculated', groupId: group.id}).then((res) => {
              const data = {
                typeName:  res[0]?.group.subtype.type.name,
                subtypeName: res[0]?.group.subtype.name,
                groupName: group.name,
                data: res,
                ord: group.ord
              }
              console.log(res, 2222)
              this.tableData.push(data)
              this.tableData.sort((a, b) => a.ord-b.ord)
              this.tableData.forEach(el => {
                this.isEmpty += el.data?.length
              })
              setTimeout(() => {
                this.loader = false
              }, 1000)
            })
          })
        })
      } else {
        this.allSubtypes.forEach(subtype => {
          if (subtype.id) {
            this.groups({subtypeId: subtype.id}).then(() => {
              this.getGroups.forEach(group => {
                this.indicators({year: this.year.value, regionId: this.region.id, type: 'Calculated', groupId: group.id}).then((res) => {
                  const data = {
                    typeName: res[0]?.group.subtype.type.name,
                    subtypeName: res[0]?.group.subtype.name,
                    groupName: group.name,
                    data: res,
                    ord: res[0]?.group.subtype.type.ord
                  }
                  this.tableData.push(data)
                  this.tableData.sort((a, b) => a.ord-b.ord)
                  this.tableData.forEach(el => {
                    this.isEmpty += el.data?.length
                  })
                  setTimeout(() => {
                    this.loader = false
                  }, 1000)
                })
              })
            })
          } else {
            this.tableData.forEach(el => {
              this.isEmpty += el.data?.length
            })
          }
        })
      }
    },
  },

  created () {
    this.setIndicators([])
    this.setDataRegions([])
    this.setYears([])
    if (this.$route.query?.year) {
      this.loader = true
      this.year = {
        name: +this.$route.query.year,
        value: +this.$route.query.year
      };

      setTimeout(() => {
        this.years().then((res) => {
          let data = []
          if (this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin') {
            res.forEach(el => {
              let year = {
                name: el.id,
                value: el.id
              }
              data.push(year)
            })
          } else {
            res.forEach(el => {
              if (el.isPublic) {
                let year = {
                  name: el.id,
                  value: el.id
                }
                data.push(year)
              }
            })
          }
          this.setYears(data)
        })
      }, 1000)

      this.regions({skip: 0, take: 200}).then((res) => {
        this.setDataRegions(res.data)
        this.region = this.dataRegions.find(el => el.id === +this.$route.query.regionId)
        this.regionTypes(this.year.value).then((res) => {
          this.typeOptions = res
          this.typeOptions.push({name: 'Все', value: 'all', id: 0})
          this.typeOptions.sort((a, b) => a.id-b.id)
          this.type = {name: 'Все', value: 'all', id: 0}
          this.subtypeOptions.push({name: 'Все', value: 'all', id: 0})
          this.typeOptions.forEach(type => {
            if(type.id) {
              this.regionSubtypes({typeId: type.id}).then((res) => {
                res.forEach(subtype => {
                  this.allSubtypes.push(subtype)
                  this.subtypeOptions.sort((a, b) => a.id-b.id)
                  this.subtype = {name: 'Все', value: 'all', id: 0}
                })
              })
            }
          })
          setTimeout(() => {
            this.getRegionGroups()
          }, 1000)
        })
      })

    } else {
      setTimeout(() => {
        this.years().then((res) => {
          let data = []
          if (this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin') {
            res.forEach(el => {
              let year = {
                name: el.id,
                value: el.id
              }
              data.push(year)
            })
          } else {
            res.forEach(el => {
              if (el.isPublic) {
                let year = {
                  name: el.id,
                  value: el.id
                }
                data.push(year)
              }
            })
          }
          this.setYears(data)
          this.year = data[data?.length-1];
        })
      }, 1000)
      this.getRegions()
    }
  }
}
</script>

<style lang="scss" scoped>
.view-indicators {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 500px;

  @media (max-width: 1000px) {
    overflow-x: auto;
  }

  &__select {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 433px;
    width: 100%;

    ::v-deep .ui-select {
      z-index: auto;
      max-width: 305px;

      &__label {
        display: none;
      }

      &__inner-text {
        font-family: "Gotham Pro Regular";
      }
    }

    ::v-deep .ui-select__label {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      p {
        font-size: 16px;
        line-height: 19px;
        color: #1B1A1F;
        white-space: nowrap;
      }
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    &-actions {
      display: flex;
      align-items: center;
      gap: 10px;

      .actions-item {
        width: 100%;
        max-width: fit-content;
        display: flex;
        gap: 5px;
        padding: 10px 20px;
        background-color: #FFFFFF;
        height: 44px;
        border-radius: 10px;
        box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);

        span {
          color: #9A9A9A;
          font-family: 'Gotham Pro Medium';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 103.7%;
        }

        p {
          color: #1B1A1F;
          font-family: 'Gotham Pro Medium';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 103.7%;
        }
      }
    }
  }
}
</style>
